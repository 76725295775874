import React, { useState } from 'react'
import { API_ENDPOINTS } from '../../config'
import toast from 'react-hot-toast'
import { FaAirbnb, FaBitcoin } from 'react-icons/fa'
import { CiTextAlignLeft } from 'react-icons/ci'
import { SiDoordash, SiUber, SiSlack, SiNetflix, SiMeta } from 'react-icons/si'
import { TbNumbers } from 'react-icons/tb'
import { LuListTodo } from 'react-icons/lu'

// Column type options
const COLUMN_TYPES = {
  STRING: 'str',
  MULTI_SELECT: 'multi-select',
  NUMBER: 'number',
}

// Multi-select options for demonstration
const MULTI_SELECT_OPTIONS = {
  'Target Markets': ['B2B', 'B2C', 'Enterprise', 'Consumer', 'SMB'],
  'Employee Count': [
    '1-50',
    '51-200',
    '201-500',
    '501-1000',
    '1001-5000',
    '5000+',
  ],
}

// Add this constant at the top of the file, after COLUMN_TYPES
const TYPE_EMOJIS = {
  [COLUMN_TYPES.STRING]: <CiTextAlignLeft />,
  [COLUMN_TYPES.MULTI_SELECT]: <LuListTodo />,
  [COLUMN_TYPES.NUMBER]: <TbNumbers />,
}

// Add this constant after your other constants
const COMPANY_ICONS = {
  Airbnb: FaAirbnb,
  Coinbase: FaBitcoin,
  DoorDash: SiDoordash,
  Uber: SiUber,
  Slack: SiSlack,
  Netflix: SiNetflix,
  Meta: SiMeta,
  // Add more mappings as needed
}

// Add this utility function
const getCompanyIcon = (companyName) => {
  const IconComponent = COMPANY_ICONS[companyName]
  if (IconComponent) {
    return <IconComponent className="w-5 h-5 text-gray-500" />
  }
  // Default icon for unknown companies
  return (
    <div className="w-5 h-5 rounded-full bg-gray-200 flex items-center justify-center">
      {companyName.charAt(0)}
    </div>
  )
}

const DashboardGrid = () => {
  const [loading, setLoading] = useState(false)
  const [rowInput, setRowInput] = useState('')
  const [columnInput, setColumnInput] = useState('')
  const [gridData, setGridData] = useState(null)
  const [editingCell, setEditingCell] = useState(null)
  const [columnTypes, setColumnTypes] = useState({})

  // Handle the initial grid generation
  const handleGenerateGrid = (e) => {
    e.preventDefault()

    try {
      // Parse inputs into arrays
      const rows = rowInput.split(',').map((item) => item.trim())
      const columns = columnInput.split(',').map((item) => item.trim())

      // Initialize column types
      const initialColumnTypes = {}
      columns.forEach((col) => {
        if (
          col.includes('Count') ||
          col.includes('Amount') ||
          col.includes('Value')
        ) {
          initialColumnTypes[col] = COLUMN_TYPES.NUMBER
        } else if (col === 'Target Markets' || col.includes('Type')) {
          initialColumnTypes[col] = COLUMN_TYPES.MULTI_SELECT
        } else {
          initialColumnTypes[col] = COLUMN_TYPES.STRING
        }
      })
      setColumnTypes(initialColumnTypes)

      // Create empty grid data
      const emptyData = rows.map(() => columns.map(() => ''))

      setGridData({
        rows,
        columns,
        data: emptyData,
      })

      // Remove success toast
    } catch (err) {
      toast.error('Failed to generate grid')
    }
  }

  // Handle the API call to populate the grid
  const handlePopulateGrid = async () => {
    if (!gridData) return

    setLoading(true)

    try {
      const response = await fetch(
        `${API_ENDPOINTS.GRID}?${new URLSearchParams({
          rows: gridData.rows.join(','),
          columns: gridData.columns.join(','),
        })}`
      )

      if (!response.ok) {
        throw new Error('Failed to fetch grid data')
      }

      const data = await response.json()
      setGridData(data)
      // Remove success toast
    } catch (err) {
      toast.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  // Handle cell editing
  const handleCellEdit = (rowIndex, columnIndex, value) => {
    if (!gridData) return

    const columnName = gridData.columns[columnIndex]
    const columnType = columnTypes[columnName]

    // Validate input based on column type
    if (columnType === COLUMN_TYPES.NUMBER) {
      if (isNaN(value) && value !== '') {
        toast.error('Please enter a valid number')
        return
      }
    }

    const newData = {
      ...gridData,
      data: gridData.data.map((row, rIndex) => {
        if (rIndex === rowIndex) {
          return row.map((cell, cIndex) => {
            if (cIndex === columnIndex) {
              return value
            }
            return cell
          })
        }
        return row
      }),
    }

    setGridData(newData)
    setEditingCell(null)
  }

  // Render cell content based on type
  const renderCell = (value, rowIndex, columnIndex) => {
    const columnName = gridData.columns[columnIndex]
    const columnType = columnTypes[columnName]

    if (editingCell?.row === rowIndex && editingCell?.col === columnIndex) {
      if (columnType === COLUMN_TYPES.MULTI_SELECT) {
        return (
          <select
            value={value}
            onChange={(e) =>
              handleCellEdit(rowIndex, columnIndex, e.target.value)
            }
            onBlur={() => setEditingCell(null)}
            className="w-full p-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
            autoFocus
          >
            <option value="">Select...</option>
            {MULTI_SELECT_OPTIONS[columnName]?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        )
      }

      return (
        <input
          type={columnType === COLUMN_TYPES.NUMBER ? 'number' : 'text'}
          value={value}
          onChange={(e) =>
            handleCellEdit(rowIndex, columnIndex, e.target.value)
          }
          onBlur={() => setEditingCell(null)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleCellEdit(rowIndex, columnIndex, e.target.value)
            }
          }}
          className="w-full p-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
          autoFocus
        />
      )
    }

    // Render multi-select values with tags
    if (columnType === COLUMN_TYPES.MULTI_SELECT && value) {
      const values = value.split(',').map((v) => v.trim())
      return (
        <div className="flex flex-wrap gap-1">
          {values.map((v, i) => (
            <span
              key={i}
              className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800"
            >
              {v}
            </span>
          ))}
        </div>
      )
    }

    return (
      <div className="cursor-pointer hover:bg-gray-50 p-1 rounded min-h-[2rem] flex items-center">
        {value}
      </div>
    )
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex-1 overflow-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {/* Header */}
          <div className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900">Research Grid</h2>
            <p className="mt-1 text-sm text-gray-500">
              Automate manual research and compare with AI to obtain a
              comprehensive overview and actionable insights
            </p>
          </div>

          {/* Input Form */}
          <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
            <form onSubmit={handleGenerateGrid} className="space-y-4">
              <div>
                <label
                  htmlFor="row-input"
                  className="block text-sm font-medium text-gray-700"
                >
                  Row Items
                </label>
                <p className="text-xs text-gray-500 mb-1">
                  Enter items separated by commas (e.g., Airbnb, Coinbase,
                  DoorDash)
                </p>
                <input
                  type="text"
                  id="row-input"
                  value={rowInput}
                  onChange={(e) => setRowInput(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter row items..."
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="column-input"
                  className="block text-sm font-medium text-gray-700"
                >
                  Column Items
                </label>
                <p className="text-xs text-gray-500 mb-1">
                  Enter items separated by commas (e.g., Founders, Target
                  Markets, Employee Count)
                </p>
                <input
                  type="text"
                  id="column-input"
                  value={columnInput}
                  onChange={(e) => setColumnInput(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter column items..."
                  required
                />
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  id="generate-grid-btn"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white 
                    bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  Generate Grid
                </button>
              </div>
            </form>
          </div>

          {/* Grid Table */}
          {gridData && (
            <div className="space-y-8 mb-8">
              <div className="bg-white rounded-lg shadow">
                <div className="overflow-x-auto">
                  <div className="inline-block min-w-full align-middle">
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-200"
                          >
                            Items
                          </th>
                          {gridData.columns.map((column, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-200"
                            >
                              <div className="flex items-center gap-2">
                                <span className="text-sm font-medium text-gray-400">
                                  {TYPE_EMOJIS[columnTypes[column]]}
                                </span>
                                <span>{column}</span>
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {gridData.rows.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border border-gray-200">
                              <div className="flex items-center gap-2">
                                {getCompanyIcon(row)}
                                <span>{row}</span>
                              </div>
                            </td>
                            {gridData.data[rowIndex].map(
                              (cell, columnIndex) => (
                                <td
                                  key={columnIndex}
                                  className="px-6 py-4 text-sm text-gray-500 border border-gray-200"
                                  onClick={() =>
                                    setEditingCell({
                                      row: rowIndex,
                                      col: columnIndex,
                                    })
                                  }
                                >
                                  {renderCell(cell, rowIndex, columnIndex)}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* Research Button */}
              <div className="flex justify-center">
                <button
                  onClick={handlePopulateGrid}
                  id="research-ai-btn"
                  disabled={loading}
                  className={`inline-flex items-center px-6 py-2.5 border border-gray-300 
                    text-sm font-medium rounded-md shadow-sm
                    ${loading ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-700 hover:bg-gray-50'} 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 
                    transition-all duration-200`}
                >
                  {loading ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Researching...
                    </>
                  ) : (
                    <>
                      <svg
                        className="mr-2 h-5 w-5 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 10V3L4 14h7v7l9-11h-7z"
                        />
                      </svg>
                      Research with AI
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DashboardGrid
