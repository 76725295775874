import React, { useState, useMemo, useEffect } from 'react'
import ChatInterface from './ChatInterface'
import { API_ENDPOINTS } from '../../config'
import useWebSocket from '../../hooks/useWebSocket'
import { UIActionProvider } from '../../contexts/UIActionContext'
import ActionFeedback from '../UI/ActionFeedback'

const ChatbotInner = ({
  isOpen,
  onClose,
  messages,
  onNewMessage,
  isWindowMode = false,
  onNewChat,
  conversationId,
  isWaitingResponse,
  isFocused,
}) => {
  const [input, setInput] = useState('')
  const [shouldRender, setShouldRender] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const [prevWindowMode, setPrevWindowMode] = useState(isWindowMode)

  // Memoize the WebSocket options to prevent reconnections
  const wsOptions = useMemo(
    () => ({
      onMessage: (data) => {
        switch (data.type) {
          case 'stream':
            onNewMessage(
              {
                type: 'bot',
                content: data.message.content,
              },
              true
            )
            break
          case 'complete':
            onNewMessage({ type: 'complete' })
            break
          case 'history':
            const transformedMessages = data.messages.map((msg) => {
              switch (msg.type) {
                case 'agent_transfer':
                  return {
                    type: 'agent_transfer',
                    content: `Transferred to ${msg.metadata?.agent_name || msg.metadata?.to || 'unknown agent'}`,
                    metadata: msg.metadata,
                  }
                case 'tool_call':
                  const content = `${msg.tool_calls?.[0]?.type} with ${JSON.stringify(msg.tool_calls?.[0]?.params)}`
                  return {
                    type: 'tool_call',
                    content: content,
                    metadata: msg.metadata,
                  }
                default:
                  return {
                    type: msg.type === 'user' ? 'user' : 'bot',
                    content: msg.content,
                  }
              }
            })
            onNewMessage(transformedMessages, false, true)
            break
          case 'agent_transfer':
            onNewMessage({
              type: 'agent_transfer',
              content: `Transferred to ${data.agent_name}`,
              metadata: { agent_name: data.agent_name },
            })
            break
          case 'tool_call':
            const content = `${data.tool_calls?.[0]?.type} with ${JSON.stringify(data.tool_calls?.[0]?.params)}`
            onNewMessage({
              type: 'tool_call',
              content: content,
            })
            break
          case 'ui_action':
            onNewMessage({
              type: 'ui_action',
              content: data.action,
              metadata: data.action,
            })
            break
          default:
            console.warn('Unknown message type:', data.type)
        }
      },
      onError: (error) => {
        console.error('WebSocket error:', error)
        onNewMessage({
          type: 'bot',
          content: 'Sorry, there was an error connecting to the chat service.',
        })
      },
      onClose: () => {
        console.log('WebSocket Disconnected')
      },
    }),
    [onNewMessage]
  )

  const { sendMessage } = useWebSocket(
    API_ENDPOINTS.CHAT_WS,
    wsOptions,
    conversationId
  )

  // Handle mounting/unmounting with animations
  useEffect(() => {
    if (isOpen) {
      setShouldRender(true)
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setIsAnimating(true)
        })
      })
    } else {
      setIsAnimating(false)
      const timer = setTimeout(() => {
        setShouldRender(false)
      }, 100)
      return () => clearTimeout(timer)
    }
  }, [isOpen])

  // Handle window mode changes
  useEffect(() => {
    if (isWindowMode !== prevWindowMode) {
      setIsAnimating(false)
      const timer = setTimeout(() => {
        setPrevWindowMode(isWindowMode)
        requestAnimationFrame(() => {
          setIsAnimating(true)
        })
      }, 25)
      return () => clearTimeout(timer)
    }
  }, [isWindowMode, prevWindowMode])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!input.trim()) return

    sendMessage({ type: 'message', content: input })
    onNewMessage({ type: 'user', content: input })
    setInput('')
  }

  if (!shouldRender) return null

  const chatContainerClassName = isWindowMode
    ? `w-full h-full md:w-[90%] md:h-[90vh] lg:w-[90%] lg:max-w-5xl lg:h-[85vh] 
       bg-white rounded-lg shadow-xl overflow-hidden
       transition-all duration-100 ease-in-out transform
       ${isAnimating ? 'scale-100 opacity-100 translate-y-0' : 'scale-95 opacity-0 translate-y-4'}`
    : `w-full h-full bg-white shadow-xl overflow-hidden rounded-lg
       transition-all duration-100 ease-in-out transform
       ${isAnimating ? 'translate-x-0 opacity-100' : 'translate-x-8 opacity-0'}`

  return (
    <>
      {isWindowMode ? (
        // Window mode - centered overlay
        <div
          className="fixed inset-0 z-[60] bg-black/50 backdrop-blur-sm flex items-center justify-center p-4"
          onClick={onClose}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full h-full flex items-center justify-center"
          >
            <ChatInterface
              messages={messages}
              onNewMessage={onNewMessage}
              onClose={onClose}
              onNewChat={onNewChat}
              input={input}
              setInput={setInput}
              handleSubmit={handleSubmit}
              containerClassName={chatContainerClassName}
              isOpen={isOpen}
              isWaitingResponse={isWaitingResponse}
              isFocused={isFocused}
            />
          </div>
        </div>
      ) : (
        // Sidebar mode - with margins and rounded corners
        <div className="h-[calc(100vh-2rem)] my-4 mr-4">
          <div className="h-full w-[480px] bg-white shadow-xl rounded-lg border border-gray-200/50">
            <ChatInterface
              messages={messages}
              onNewMessage={onNewMessage}
              onClose={onClose}
              onNewChat={onNewChat}
              input={input}
              setInput={setInput}
              handleSubmit={handleSubmit}
              containerClassName={chatContainerClassName}
              isOpen={isOpen}
              isWaitingResponse={isWaitingResponse}
              isFocused={isFocused}
            />
          </div>
        </div>
      )}
      <ActionFeedback />
    </>
  )
}

// Wrapper component that provides the UIActionContext
const Chatbot = (props) => {
  return (
    <UIActionProvider>
      <ChatbotInner {...props} />
    </UIActionProvider>
  )
}

export default Chatbot
