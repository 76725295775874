import React from 'react'
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
  Cell,
  ComposedChart,
  ZAxis,
} from 'recharts'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

// Add these color constants at the top
const CHART_COLORS = {
  primary: '#6366f1', // Indigo-500
  secondary: '#a855f7', // Purple-500
  accent: '#ec4899', // Pink-500
  neutral: '#6b7280', // Gray-500
  background: '#ffffff',
  gradient: ['#6366f1', '#a855f7', '#ec4899'],
}

const CHART_STYLES = {
  gridColor: '#f3f4f6', // Gray-100
  tooltipBackground: 'rgba(255, 255, 255, 0.95)',
  fontFamily: 'Inter, system-ui, sans-serif',
}

// Add this before the DashboardCanvas component
const sampleData = {
  content: [
    {
      type: 'visualization',
      chartType: 'map',
      title: 'Global Office Locations',
      width: 'full',
      data: [
        {
          lat: 37.7749,
          lng: -122.4194,
          title: 'San Francisco HQ',
          description: 'Main headquarters and engineering hub',
        },
        {
          lat: 40.7128,
          lng: -74.006,
          title: 'New York Office',
          description: 'East coast operations and sales',
        },
        {
          lat: 51.5074,
          lng: -0.1278,
          title: 'London Office',
          description: 'European headquarters',
        },
        {
          lat: 35.6762,
          lng: 139.6503,
          title: 'Tokyo Office',
          description: 'Asia Pacific operations',
        },
        {
          lat: -33.8688,
          lng: 151.2093,
          title: 'Sydney Office',
          description: 'Australia and NZ operations',
        },
      ],
      config: {
        center: [20, 0], // Center of the world
        zoom: 2,
        // You can choose different tile layers:
        // Default OpenStreetMap
        tileLayer: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        // OR Stamen Terrain
        // tileLayer: 'https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg',
        // OR CartoDB
        // tileLayer: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
      },
    },
    {
      type: 'summary',
      content: 'Sales have shown an upward trend with a notable peak in April.',
      width: 'full',
    },
    {
      type: 'visualization',
      chartType: 'pie',
      title: 'Revenue Distribution',
      width: 'half',
      data: [
        { name: 'Product A', value: 400 },
        { name: 'Product B', value: 300 },
        { name: 'Product C', value: 200 },
      ],
      config: {
        name: 'name',
        value: 'value',
      },
    },
    {
      type: 'visualization',
      chartType: 'bar',
      title: 'Monthly Performance',
      width: 'half',
      data: [
        { month: 'Jan', sales: 800 },
        { month: 'Feb', sales: 900 },
        { month: 'Mar', sales: 1200 },
      ],
      config: {
        xAxis: 'month',
        yAxis: 'sales',
      },
    },
    {
      type: 'visualization',
      chartType: 'line',
      title: 'Sales Trend',
      width: 'full', // 'half' or 'full'
      data: [
        { month: 'Jan', value: 1000 },
        { month: 'Feb', value: 1500 },
        { month: 'Mar', value: 1300 },
        { month: 'Apr', value: 1800 },
      ],
      config: {
        xAxis: 'month',
        yAxis: 'value',
      },
    },
    {
      type: 'visualization',
      chartType: 'table',
      title: 'Performance Metrics',
      width: 'full',
      data: {
        headers: ['Metric', 'Q1', 'Q2', 'Q3', 'Q4', 'YoY Growth'],
        rows: [
          ['Revenue', '$1.2M', '$1.5M', '$1.8M', '$2.1M', '+75%'],
          ['Users', '15K', '18K', '24K', '30K', '+100%'],
          ['Conversion', '2.4%', '2.8%', '3.2%', '3.8%', '+58%'],
          ['Churn Rate', '1.8%', '1.6%', '1.4%', '1.2%', '-33%'],
        ],
      },
      config: {
        striped: true,
        highlightOnHover: true,
        compact: false,
        formatters: {
          'YoY Growth': (value) => ({
            className: value.startsWith('+')
              ? 'text-green-600'
              : 'text-red-600',
            value: value,
          }),
        },
      },
    },
    {
      type: 'visualization',
      chartType: 'donut',
      title: 'Customer Segments',
      width: 'half',
      data: [
        { name: 'Enterprise', value: 450 },
        { name: 'Small Business', value: 300 },
        { name: 'Individual', value: 250 },
        { name: 'Government', value: 200 },
      ],
      config: {
        name: 'name',
        value: 'value',
      },
    },
    {
      type: 'visualization',
      chartType: 'scatter',
      title: 'Product Performance',
      width: 'half',
      data: [
        { price: 100, satisfaction: 85, sales: 200, product: 'Product A' },
        { price: 200, satisfaction: 88, sales: 150, product: 'Product B' },
        { price: 150, satisfaction: 90, sales: 300, product: 'Product C' },
        { price: 300, satisfaction: 95, sales: 100, product: 'Product D' },
        { price: 250, satisfaction: 87, sales: 250, product: 'Product E' },
      ],
      config: {
        xAxis: 'price',
        yAxis: 'satisfaction',
        zAxis: 'sales',
        name: 'Products',
      },
    },
    {
      type: 'visualization',
      chartType: 'histogram',
      title: 'Order Value Distribution',
      width: 'half',
      data: [
        { range: '0-50', count: 120 },
        { range: '51-100', count: 250 },
        { range: '101-150', count: 180 },
        { range: '151-200', count: 90 },
        { range: '201+', count: 60 },
      ],
      config: {
        xAxis: 'range',
        yAxis: 'count',
        barSize: 30,
      },
    },
    {
      type: 'visualization',
      chartType: 'boxplot',
      title: 'Monthly Sales Distribution',
      width: 'half',
      data: [
        { month: 'Jan', min: 500, max: 1500, median: 1000, q1: 750, q3: 1250 },
        { month: 'Feb', min: 600, max: 1700, median: 1200, q1: 900, q3: 1400 },
        { month: 'Mar', min: 800, max: 2000, median: 1400, q1: 1100, q3: 1600 },
        { month: 'Apr', min: 900, max: 2200, median: 1600, q1: 1300, q3: 1900 },
      ],
      config: {
        xAxis: 'month',
      },
    },
    {
      type: 'visualization',
      chartType: 'area',
      title: 'User Growth Over Time',
      width: 'half',
      data: [
        { month: 'Jan', users: 1000, activeUsers: 800 },
        { month: 'Feb', users: 1500, activeUsers: 1200 },
        { month: 'Mar', users: 2000, activeUsers: 1600 },
        { month: 'Apr', users: 2500, activeUsers: 2000 },
        { month: 'May', users: 3000, activeUsers: 2400 },
      ],
      config: {
        xAxis: 'month',
        yAxis: 'users',
      },
    },
    {
      type: 'visualization',
      chartType: 'bubble',
      title: 'Market Analysis',
      width: 'half',
      data: [
        {
          market: 'US',
          revenue: 800,
          growth: 20,
          size: 400,
          name: 'US Market',
        },
        {
          market: 'EU',
          revenue: 600,
          growth: 15,
          size: 300,
          name: 'EU Market',
        },
        {
          market: 'Asia',
          revenue: 400,
          growth: 30,
          size: 200,
          name: 'Asia Market',
        },
        {
          market: 'LATAM',
          revenue: 200,
          growth: 25,
          size: 100,
          name: 'LATAM Market',
        },
      ],
      config: {
        xAxis: 'revenue',
        yAxis: 'growth',
        zAxis: 'size',
        name: 'Markets',
        size: 'size',
      },
    },
    {
      type: 'visualization',
      chartType: 'cluster',
      title: 'Customer Segmentation',
      width: 'full',
      data: [
        { x: 82, y: 75, cluster: 'High Value', size: 120, label: 'Segment A' },
        { x: 78, y: 68, cluster: 'High Value', size: 90, label: 'Segment B' },
        { x: 84, y: 78, cluster: 'High Value', size: 110, label: 'Segment C' },
        { x: 45, y: 35, cluster: 'Low Value', size: 70, label: 'Segment D' },
        { x: 40, y: 38, cluster: 'Low Value', size: 60, label: 'Segment E' },
        { x: 35, y: 42, cluster: 'Low Value', size: 50, label: 'Segment F' },
        { x: 58, y: 55, cluster: 'Medium Value', size: 85, label: 'Segment G' },
        { x: 62, y: 52, cluster: 'Medium Value', size: 95, label: 'Segment H' },
        { x: 55, y: 48, cluster: 'Medium Value', size: 80, label: 'Segment I' },
      ],
      config: {
        xAxis: 'x',
        yAxis: 'y',
        clusterKey: 'cluster',
        sizeKey: 'size',
        labelKey: 'label',
        clusters: {
          'High Value': '#4ade80', // Green
          'Medium Value': '#facc15', // Yellow
          'Low Value': '#f87171', // Red
        },
      },
    },
  ],
}

// Add this after imports to fix Leaflet marker icon issue
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

// Component to render different chart types
const ChartRenderer = ({ type, data, config }) => {
  const commonProps = {
    className:
      'transition-all duration-300 ease-in-out rounded-xl p-4 bg-white',
    style: { fontFamily: CHART_STYLES.fontFamily },
  }

  switch (type) {
    case 'line':
      return (
        <ResponsiveContainer width="100%" height={300} {...commonProps}>
          <LineChart data={data} className="transition-transform duration-300">
            <defs>
              <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={CHART_COLORS.primary}
                  stopOpacity={0.2}
                />
                <stop
                  offset="95%"
                  stopColor={CHART_COLORS.primary}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <CartesianGrid
              stroke={CHART_STYLES.gridColor}
              strokeDasharray="3 3"
            />
            <XAxis
              dataKey={config.xAxis}
              tick={{ fill: CHART_COLORS.neutral }}
              axisLine={{ stroke: CHART_STYLES.gridColor }}
            />
            <YAxis
              tick={{ fill: CHART_COLORS.neutral }}
              axisLine={{ stroke: CHART_STYLES.gridColor }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: CHART_STYLES.tooltipBackground,
                borderRadius: '0.5rem',
                border: 'none',
                boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
              }}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey={config.yAxis}
              stroke={CHART_COLORS.primary}
              strokeWidth={2}
              dot={{ fill: CHART_COLORS.primary, strokeWidth: 2 }}
              activeDot={{ r: 6, fill: CHART_COLORS.primary }}
              fillOpacity={1}
              fill="url(#lineGradient)"
            />
          </LineChart>
        </ResponsiveContainer>
      )

    case 'bar':
      return (
        <ResponsiveContainer width="100%" height={300} {...commonProps}>
          <BarChart data={data}>
            <defs>
              <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="0%"
                  stopColor={CHART_COLORS.primary}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={CHART_COLORS.secondary}
                  stopOpacity={0.8}
                />
              </linearGradient>
            </defs>
            <CartesianGrid
              stroke={CHART_STYLES.gridColor}
              strokeDasharray="3 3"
            />
            <XAxis
              dataKey={config.xAxis}
              tick={{ fill: CHART_COLORS.neutral }}
              axisLine={{ stroke: CHART_STYLES.gridColor }}
            />
            <YAxis
              tick={{ fill: CHART_COLORS.neutral }}
              axisLine={{ stroke: CHART_STYLES.gridColor }}
            />
            <Tooltip
              cursor={{ fill: 'rgba(99, 102, 241, 0.1)' }}
              contentStyle={{
                backgroundColor: CHART_STYLES.tooltipBackground,
                borderRadius: '0.5rem',
                border: 'none',
                boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
              }}
            />
            <Legend />
            <Bar
              dataKey={config.yAxis}
              fill="url(#barGradient)"
              radius={[4, 4, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      )

    case 'pie':
      return (
        <ResponsiveContainer width="100%" height={300} {...commonProps}>
          <PieChart>
            <Pie
              data={data}
              dataKey={config.value}
              nameKey={config.name}
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill={CHART_COLORS.primary}
              label={{
                fill: CHART_COLORS.neutral,
                fontSize: 12,
              }}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    CHART_COLORS.gradient[index % CHART_COLORS.gradient.length]
                  }
                />
              ))}
            </Pie>
            <Tooltip
              contentStyle={{
                backgroundColor: CHART_STYLES.tooltipBackground,
                borderRadius: '0.5rem',
                border: 'none',
                boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
              }}
            />
            <Legend verticalAlign="bottom" height={36} iconType="circle" />
          </PieChart>
        </ResponsiveContainer>
      )

    case 'map':
      if (data[0]?.lat !== undefined) {
        return (
          <div
            className="h-[500px] w-full relative"
            style={{
              ...commonProps,
              zIndex: 0,
            }}
          >
            <MapContainer
              center={config.center || [20, 0]}
              zoom={config.zoom || 2}
              minZoom={2}
              maxBounds={[
                [-85, -Infinity],
                [85, Infinity],
              ]}
              maxBoundsViscosity={1.0}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '1.5rem',
                background: '#f8fafc',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
              }}
              scrollWheelZoom={true}
            >
              <TileLayer
                attribution=""
                url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png?api_key=9174eaa7-3909-4f40-8086-cbdd9ce0b068"
                noWrap={false}
                bounds={[
                  [-85, -180],
                  [85, 180],
                ]}
              />
              {data.map((location, index) => (
                <Marker
                  key={index}
                  position={[location.lat, location.lng]}
                  icon={L.divIcon({
                    className: 'custom-marker-icon',
                    html: `
                      <div class="marker-pin-wrapper">
                        <div class="marker-pin">
                          <div class="marker-pin-inner"></div>
                        </div>
                        <div class="marker-pulse"></div>
                      </div>
                    `,
                    iconSize: [50, 50],
                    iconAnchor: [25, 50],
                  })}
                >
                  <Popup>
                    <div className="p-4 min-w-[250px]">
                      <h3 className="text-lg font-bold mb-2 text-indigo-600">
                        {location.title}
                      </h3>
                      {location.description && (
                        <p className="text-gray-600 text-sm leading-relaxed">
                          {location.description}
                        </p>
                      )}
                    </div>
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          </div>
        )
      }

    case 'donut':
      return (
        <ResponsiveContainer width="100%" height={300} {...commonProps}>
          <PieChart>
            <Pie
              data={data}
              dataKey={config.value}
              nameKey={config.name}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill={CHART_COLORS.primary}
              label={{
                fill: CHART_COLORS.neutral,
                fontSize: 12,
              }}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    CHART_COLORS.gradient[index % CHART_COLORS.gradient.length]
                  }
                />
              ))}
            </Pie>
            <Tooltip
              contentStyle={{
                backgroundColor: CHART_STYLES.tooltipBackground,
                borderRadius: '0.5rem',
                border: 'none',
                boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
              }}
            />
            <Legend verticalAlign="bottom" height={36} iconType="circle" />
          </PieChart>
        </ResponsiveContainer>
      )

    case 'scatter':
      return (
        <ResponsiveContainer width="100%" height={300}>
          <ScatterChart>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={config.xAxis} />
            <YAxis dataKey={config.yAxis} />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} />
            <Legend />
            <Scatter name={config.name} data={data} fill="#8884d8" />
          </ScatterChart>
        </ResponsiveContainer>
      )

    case 'histogram':
      return (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={config.xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey={config.yAxis}
              fill="#8884d8"
              barSize={config.barSize || 20}
            />
          </BarChart>
        </ResponsiveContainer>
      )

    case 'boxplot':
      return (
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={config.xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="min" fill="#8884d8" opacity={0.5} />
            <Bar dataKey="max" fill="#8884d8" opacity={0.5} />
            <Line dataKey="median" stroke="#8884d8" dot={false} />
          </ComposedChart>
        </ResponsiveContainer>
      )

    case 'area':
      return (
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={config.xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Area
              type="monotone"
              dataKey={config.yAxis}
              fill="#8884d8"
              stroke="#8884d8"
            />
          </AreaChart>
        </ResponsiveContainer>
      )

    case 'bubble':
      return (
        <ResponsiveContainer width="100%" height={300}>
          <ScatterChart>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={config.xAxis} />
            <YAxis dataKey={config.yAxis} />
            <ZAxis dataKey={config.zAxis} range={[0, 500]} />
            <Tooltip />
            <Legend />
            <Scatter data={data} name={config.name}>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill="#8884d8"
                  opacity={0.7}
                  r={entry[config.size] / 10} // Adjust the radius based on size
                />
              ))}
            </Scatter>
          </ScatterChart>
        </ResponsiveContainer>
      )

    case 'table':
      return (
        <div className="w-full overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-200">
              <tr>
                {data.headers.map((header, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody
              className={`bg-white divide-y divide-gray-200 ${
                config.striped ? 'divide-y divide-gray-200' : ''
              }`}
            >
              {data.rows.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={`${
                    config.striped && rowIndex % 2 === 0 ? 'bg-gray-50' : ''
                  } ${
                    config.highlightOnHover
                      ? 'hover:bg-gray-50 transition-colors duration-150'
                      : ''
                  }`}
                >
                  {row.map((cell, cellIndex) => {
                    const formatter =
                      config.formatters?.[data.headers[cellIndex]]
                    const formattedCell = formatter
                      ? formatter(cell)
                      : { value: cell }

                    return (
                      <td
                        key={cellIndex}
                        className={`px-6 py-4 whitespace-nowrap text-sm ${
                          cellIndex === 0
                            ? 'font-medium text-gray-900'
                            : 'text-gray-500'
                        } ${config.compact ? 'py-2' : 'py-4'} ${
                          formattedCell.className || ''
                        }`}
                      >
                        {formattedCell.value}
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )

    case 'cluster':
      return (
        <ResponsiveContainer width="100%" height={400} {...commonProps}>
          <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={CHART_STYLES.gridColor}
            />
            <XAxis
              type="number"
              dataKey={config.xAxis}
              name="Value"
              tick={{ fill: CHART_COLORS.neutral }}
              axisLine={{ stroke: CHART_STYLES.gridColor }}
            />
            <YAxis
              type="number"
              dataKey={config.yAxis}
              name="Score"
              tick={{ fill: CHART_COLORS.neutral }}
              axisLine={{ stroke: CHART_STYLES.gridColor }}
            />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              contentStyle={{
                backgroundColor: CHART_STYLES.tooltipBackground,
                borderRadius: '0.5rem',
                border: 'none',
                boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
              }}
              formatter={(value, name, props) => [
                value,
                props.payload[config.labelKey],
              ]}
            />
            <Legend />
            {Object.keys(config.clusters).map((clusterName) => (
              <Scatter
                key={clusterName}
                name={clusterName}
                data={data.filter(
                  (item) => item[config.clusterKey] === clusterName
                )}
                fill={config.clusters[clusterName]}
              >
                {data
                  .filter((item) => item[config.clusterKey] === clusterName)
                  .map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={config.clusters[clusterName]}
                      opacity={0.7}
                    />
                  ))}
              </Scatter>
            ))}
          </ScatterChart>
        </ResponsiveContainer>
      )

    default:
      return <div>Unsupported chart type</div>
  }
}

// Update the VisualizationSection component with fixed row grouping logic
const VisualizationSection = ({ content }) => {
  // Group items into rows based on their width
  const rows = content.reduce((acc, item) => {
    const lastRow = acc[acc.length - 1]

    // Always start a new row if:
    // 1. There are no rows yet
    // 2. Current item is full width
    // 3. Previous row has a full width item
    // 4. Last row already has two half-width items
    if (
      !lastRow ||
      item.width === 'full' ||
      lastRow.items[0]?.width === 'full' ||
      lastRow.items.length === 2
    ) {
      acc.push({ items: [item] })
    } else {
      // Only add to existing row if both items are half width
      if (lastRow.items[0]?.width === 'half' && item.width === 'half') {
        lastRow.items.push(item)
      } else {
        // Start new row for any other case
        acc.push({ items: [item] })
      }
    }
    return acc
  }, [])

  return (
    <div className="space-y-6">
      {rows.map((row, rowIndex) => (
        <div
          key={rowIndex}
          className={`grid gap-6 ${
            row.items.length > 1 ? 'grid-cols-1 md:grid-cols-2' : 'grid-cols-1'
          }`}
        >
          {row.items.map((item, itemIndex) => {
            if (item.type === 'visualization') {
              return (
                <div
                  key={itemIndex}
                  className={`bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 p-6 border border-gray-100 ${
                    item.width === 'full' ? 'col-span-full' : ''
                  }`}
                >
                  <h3 className="text-lg font-semibold text-gray-900 mb-4">
                    {item.title}
                  </h3>
                  <ChartRenderer
                    type={item.chartType}
                    data={item.data}
                    config={item.config}
                  />
                </div>
              )
            } else if (item.type === 'summary') {
              return (
                <div
                  key={itemIndex}
                  className={`bg-white rounded-2xl shadow-sm p-6 border border-gray-100 ${
                    item.width === 'full' ? 'col-span-full' : ''
                  }`}
                >
                  <p className="text-gray-600 leading-relaxed">
                    {item.content}
                  </p>
                </div>
              )
            }
            return null
          })}
        </div>
      ))}
    </div>
  )
}

// Update the main DashboardCanvas component
const DashboardCanvas = ({ visualizationData }) => {
  return (
    <div className="h-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="sm:flex sm:items-center mb-8">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-bold text-gray-900">Dynamic Canvas</h1>
            <p className="mt-2 text-sm text-gray-600">
              Real-time interactive visualization dashboard powered by AI
            </p>
          </div>
        </div>

        <div className="space-y-8">
          <VisualizationSection
            content={visualizationData?.content || sampleData.content}
          />
        </div>
      </div>
    </div>
  )
}

export default DashboardCanvas
