import React, { useRef, useEffect } from 'react'
import config from '../../config'
import MarkdownRenderer from '../shared/MarkdownRenderer'

const MessageContent = ({ content, type }) => {
  return <MarkdownRenderer content={content} />
}

const Message = ({ message }) => {
  const messageClass =
    message.type === 'user'
      ? 'flex justify-start mb-3'
      : 'flex justify-start mb-3'

  // Handle system messages (agent transfers, tool calls, and UI actions)
  if (
    message.type === 'agent_transfer' ||
    message.type === 'tool_call' ||
    message.type === 'ui_action'
  ) {
    const getIcon = () => {
      switch (message.type) {
        case 'tool_call':
          return 'fa-wrench'
        case 'agent_transfer':
          return 'fa-arrow-right'
        case 'ui_action':
          return 'fa-mouse-pointer'
        default:
          return 'fa-info-circle'
      }
    }

    const getText = () => {
      switch (message.type) {
        case 'tool_call':
          return `Using Tool: ${message.content}`
        case 'agent_transfer':
          return message.content
        case 'ui_action':
          return message.content
        default:
          return message.content
      }
    }

    return (
      <div className="flex justify-start mb-3 pl-2">
        <div className="bg-gray-50 text-gray-500 text-xs px-3 py-1.5 rounded-md flex items-center space-x-1.5">
          <i className={`fas ${getIcon()} text-gray-400`}></i>
          <span className="text-gray-600">{getText()}</span>
        </div>
      </div>
    )
  }

  const contentClass =
    message.type === 'user'
      ? 'max-w rounded-lg pl-2 pr-2 pt-1 pb-1 bg-gray-100'
      : 'max-w'

  return (
    <div className={messageClass}>
      <div className={contentClass}>
        <MessageContent content={message.content} type={message.type} />
      </div>
    </div>
  )
}

const LoadingIndicator = () => (
  <div className="flex justify-start mb-3">
    <div className="max-w rounded-lg pl-2 pr-2 pt-2 pb-2">
      <div className="flex space-x-2 items-center">
        {/* Typing animation dots */}
        <div className="flex space-x-1.5">
          <div
            className="w-1 h-1 bg-gray-500 rounded-full animate-pulse"
            style={{ animationDelay: '0ms', animationDuration: '1000ms' }}
          ></div>
          <div
            className="w-1 h-1 bg-gray-500 rounded-full animate-pulse"
            style={{ animationDelay: '200ms', animationDuration: '1000ms' }}
          ></div>
          <div
            className="w-1 h-1 bg-gray-500 rounded-full animate-pulse"
            style={{ animationDelay: '400ms', animationDuration: '1000ms' }}
          ></div>
        </div>
      </div>
    </div>
  </div>
)

const ChatInterface = ({
  messages,
  onNewMessage,
  onClose,
  onNewChat,
  input,
  setInput,
  handleSubmit,
  containerClassName = '',
  isOpen,
  isWaitingResponse,
  isFocused,
}) => {
  const messagesEndRef = useRef(null)
  const inputRef = useRef(null)

  // Focus input when chatbot is focused
  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isFocused])

  // Focus input on mount and when new chat starts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [messages.length])

  // Separate immediate scroll for initial render
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' })
  }, []) // Empty dependency array for initial render only

  // Smooth scroll for message updates
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  return (
    <div className={`flex flex-col h-full bg-white ${containerClassName}`}>
      {/* Header - fixed height */}
      <div
        className="p-4 border-b border-gray-200 flex justify-between items-center 
        bg-gray-700/90 backdrop-blur-md text-white shrink-0"
      >
        <h3 className="font-semibold">{config.assistant_name}</h3>
        <div className="flex items-center space-x-2">
          <button
            onClick={onNewChat}
            className="text-sm bg-gray-700 hover:bg-gray-500 px-2 py-1 rounded"
            title="Start New Conversation (⌘J)"
          >
            New Chat
          </button>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <i className="fas fa-times text-sm"></i>
          </button>
        </div>
      </div>

      {/* Messages - flexible height with scrolling */}
      <div className="flex-1 overflow-y-auto scrollbar-hide p-3 space-y-3">
        {messages.map((message, index) => (
          <Message key={index} message={message} />
        ))}
        {isWaitingResponse && <LoadingIndicator />}
        <div ref={messagesEndRef} />
      </div>

      {/* Input - fixed height */}
      <form
        onSubmit={(e) => {
          e.preventDefault()
          if (!isWaitingResponse && input.trim()) {
            handleSubmit(e)
          }
        }}
        className="p-2 border-t border-gray-200 shrink-0 bg-white"
      >
        <div className="relative">
          <input
            ref={inputRef}
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder={
              isWaitingResponse
                ? 'Waiting for response...'
                : 'Type a message...'
            }
            className={`w-full pr-8 py-1.5 px-2 text-sm bg-white border border-gray-300 
              rounded-lg focus:outline-none focus:ring-0
              ${isFocused ? 'border-gray-400' : 'border-gray-300'}
              placeholder-gray-400 transition-colors`}
          />
          <button
            type="submit"
            disabled={isWaitingResponse || !input.trim()}
            className={`absolute right-2 top-1/2 -translate-y-1/2 
              transition-colors
              ${
                isWaitingResponse || !input.trim()
                  ? 'text-gray-300 cursor-not-allowed'
                  : 'text-gray-400 hover:text-gray-600 cursor-pointer'
              }`}
          >
            <i className="fas fa-paper-plane text-sm"></i>
          </button>
        </div>
      </form>
    </div>
  )
}

export default ChatInterface
